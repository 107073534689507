import React from "react";
import { Element, Link } from "react-scroll";
import { useSetRecoilState } from "recoil";
import { downloadModalState } from "state/atoms/modals";
import { Button, Download } from "components/Common/Common";
import { useScrollOffset } from "utils/useScrollOffset";
import BookCoverImage from "assets/images/img-book-magazine.png";
import  Video  from 'components/Video/Video';
import "./LeadingContent.scss";

const LeadingContent = () => {
  const setDownloadModalState = useSetRecoilState(downloadModalState);

  return (
    <div className="leadingContent">
      <div className="leadingContent__shape" />

      <div className="leadingContent__wrapper">
        <div className="leadingContent__wrapper-brief">
          <div className="leadingContent__wrapper-brief__inner">
            <p>
              <b>Hyperautomation</b> – a Gartner term – is based on the idea
              that anything that can be automated in an organisation, should be
              automated. It’s not a tool, but rather an attitude to stay
              competitive in a fast-changing world. TheValueChain helps you with
              a pragmatic step-by-step plan, tailor-made to your needs.
            </p>

            <div className="leadingContent__wrapper-brief__inner-button">
              <a
                href="https://www.thevaluechain.eu/event/thevaluechain-proudly-presents-hyperchain#sign-up-form"
                target="__blank"
              >
              </a>
            </div>
          </div>
        </div>

        <Element name="leadingRef">
          <div className="leadingContent__wrapper-content">
            <div className="leadingContent__wrapper-content__text">
              <h3>
                TheValueChain proudly presents: <strong>HyperChain</strong>
              </h3>
              <p>
                The HyperChain team is a dedicated selection of experienced
                process optimisation consultants, 100% focused on
                hyperautomation. Our mission? To lower your total cost of SAP
                ownership (TCO) by identifying the correct use cases and
                intelligently automating your processes. Our team also wields an
                abundance of expertise in integration with third-party
                solutions.
              </p>
            </div>

            <div className="leadingContent__wrapper-content__image">
              <div className="leadingContent__wrapper-content__image-container">
                <img src={BookCoverImage} alt="book-cover" />
              </div>

              <div className="leadingContent__wrapper-content__image-cta">
                <Download onClick={() => setDownloadModalState(true)} />
              </div>
            </div>
          </div>
        </Element>
      </div>

      <Video />
    </div>
  );
};

export default LeadingContent;
