import React from "react";
import "./StepCard.scss";

const StepCard = (props) => {
  const { description, imageUrl } = props;
  return (
    <div className="stepCard">
      <div className="stepCard__wrapper">
        <div
          className="stepCard__wrapper-inner"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </div>
  );
};

export default StepCard;
