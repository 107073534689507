import React from "react";
import TVCLogo from "assets/icons/logo-tvc.svg";
import SAPLogo from "assets/icons/logo-sap.svg";
import GumptionLogo from "assets/icons/logo-gumption.svg";

import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__wrapper">
        <div className="footer__wrapper-logo">
          <a href="https://www.thevaluechain.be/" target="__blank">
            <img src={TVCLogo} alt="tvc-logo" />
          </a>
        </div>

        <div className="footer__wrapper-info">
          <div className="footer__wrapper-info__addr">
            <p>
              Satenrozen 10 <br />
              2550 Kontich, Belgium
            </p>
          </div>

          <div className="footer__wrapper-info__contact">
            <a href="tel:+3238442605">T +32 (0)3 844 26 05</a>
            <a href="mailto:info@hyperchain.eu">E info@hyperchain.eu</a>
          </div>
        </div>

        <div className="footer__wrapper-partners">
          <a href="www.sap.com" target="__blank">
            <img src={SAPLogo} alt="sap-logo" />
          </a>
          <a href="https://www.gumption.eu/" target="__blank">
            <img src={GumptionLogo} alt="gumption-logo" />
          </a>
        </div>

        <div className="footer__wrapper-privacy">
          <span>
            An odder website by{" "}
            <a href="https://purplepanda.be/" target="__blank">
              PurplePanda
            </a>
          </span>
          <span>Privacy policy &amp; cookies</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
