import React from "react";
import RectIcon from "assets/icons/icon-rectangle.svg";
import EllipseIcon from "assets/icons/icon-ellipse.svg";
import "./ShapeContent.scss";

const ShapeContent = () => {
  return (
    <div className="shapeContent">
      <div className="shapeContent__wrapper">
        <div className="shapeContent__wrapper-inner">
          <h4>
            At HyperChain, we believe it requires a specific mind and skill set
            to guide{" "}
            <strong>
              businesses to a state of hyperautomation. A dedicated team
              combining its process expertise with a strong vision
            </strong>{" "}
            that goes far beyond RPA alone, is the key to success in your
            journey towards hyperautomation.
          </h4>

          <div className="shapeContent__wrapper-ellipse">
            <img src={EllipseIcon} alt="ellipse-icon" />
          </div>

          <div className="shapeContent__wrapper-rect">
            <img src={RectIcon} alt="ellipse-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShapeContent;
