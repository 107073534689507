import React from "react";
import { useRecoilValue } from "recoil";
import { contactModalState, downloadModalState } from "state/atoms/modals";
import ContactModal from "components/ContactModal/ContactModal";
import DownloadModal from "components/DownloadModal/DownloadModal";
import Hero from "sections/Home/Hero/Hero";
import LeadingContent from "sections/Home/LeadingContent/LeadingContent";
import Approach from "sections/Home/Approach/Approach";
import ShapeContent from "sections/Home/ShapeContent/ShapeContent";
import HowValue from "sections/Home/HowValue/HowValue";
import ContactUs from "sections/Home/ContactUs/ContactUs";

const Home = () => {
  const isContactModalOpen = useRecoilValue(contactModalState);
  const isDownloadModalOpen = useRecoilValue(downloadModalState);

  return (
    <div>
      <Hero />
      <LeadingContent />
      <Approach />
      <ShapeContent />
      <HowValue />
      <ContactUs />

      <ContactModal isModalOpen={isContactModalOpen} />
      <DownloadModal isModalOpen={isDownloadModalOpen} />
    </div>
  );
};

export default Home;
