import ImageOne from "assets/images/img-case-1.png";
import ImageTwo from "assets/images/img-case-2.png";
import ImageThree from "assets/images/img-case-3.png";
import ImageFour from "assets/images/img-case-4.png";
import ImageFive from "assets/images/img-case-5.png";
import IconOne from "assets/icons/1.svg";
import IconTwo from "assets/icons/2.svg";
import IconThree from "assets/icons/3.svg";
import IconFour from "assets/icons/4.svg";
import IconFive from "assets/icons/5.svg";
import GalapagosLogo from "assets/images/logo-galapagos.png";
import AllinoxLogo from "assets/images/logo-allinox.png";
import SapienceLogo from "assets/images/logo-sapience.png";
import Q8OilsLogo from "assets/images/logo-Q8Oils.png"
import StowLogo from "assets/images/logo-stow.png"

export const valuesObj = [
  {
    text: "<p><b>By automating repetitive tasks.</b> Think big, start small. Or simply said: first the stupid work, afterwards the intelligent work. Causing an FTE shift: people can work on what’s really important to the company (e.g.: customer experience, product quality,…)</p>",
    imageUrl: ImageOne,
    logoUrl: GalapagosLogo,
    iconUrl: IconOne,
    modalContent:
      "<p>At Galapagos we automate purchase order (PO) confirmations with a clear view on FTE reduction, a more compliant process and better supply chain planning. We respect a phased approach: we focus first on the top 20 suppliers that represent 80% of the total PO processing, so we can realize quick payback. Afterwards we gradually include more exotic cases regarding variable input (e.g.: suppliers inviting Galapagos to their portal for retrieving the ETA’s). With Document Information Extraction, we’re using AI skills that can recognize the data elements in any supplier’s PDF template we receive via e-mail. We use the embedded probability rate to decide if the bots can proceed with interpreted data or if we should step out and involve a user who can confirm the data, and who’s actually training the model for next time. Every single PO confirmation receives a dedicated workflow instance. As a result, continuous process visibility with milestone information and KPI’s are available at any time.</p>",
  },
  {
    text: "<p><b>By setting up a Sales Order Automation.</b> Customer service employees have their hands full performing order input, following up on them and sending out status reports to customers. The job requires manually entering details into the system on a day-to-day basis. Exactly the kind of process that benefits from hyperautomation.</p>",
    imageUrl: ImageTwo,
    logoUrl: Q8OilsLogo,
    iconUrl: IconTwo,
    modalContent:
      "<p>Customer service employees have their hands full performing order input, following up on them and sending out status reports to customers. The job requires manually entering details into the system on a day-to-day basis. Work that is mind-numbing, repetitive, slow and error prone. Exactly the kind of process that benefits from hyperautomation. The solution we came up with relies on SAP and RPA. A bot retrieves PDF data from an e-mail, applies AI to it and creates a sales order via API's. Users might be involved to manage exceptions. Furthermore, ongoing sales orders are automatically collected per customer and sent out to them. Requests for status updates from customers are something from the past, which benefits both employee and customer.</p>",
  },
  {
    text: "<p><b>By creating low-barrier solutions.</b> No more expensive and bulky developments, but rather configuration by Citizen Developers (e.g. business users). Side-by-side extensibilities and keeping the core clean.</p>",
    imageUrl: ImageThree,
    logoUrl: AllinoxLogo,
    iconUrl: IconThree,
    modalContent:
      "<p>At Allinox the decision was made to not go for an extensive, and especially expensive, high-code development to integrate a Pricing Cockpit that sets sales price conditions for trading goods based on complex cost price elements. Instead, they opt for a low-barrier Hyperautomation solution where their current Excel is seamlessly integrated with S/4HANA. There’s a workflow constantly keeping the Excel sheet in sync with the ERP, stabilized by including error handling, and involving bot tasks to communicate via whitelisted API’s. It’s a cost-driven decision, but not only in the short term: they don’t want to depend on developers to maintain their solution, and they want to keep the core clean to remain agile (e.g.: not having to fear any upgrade).</p>",
  },
  {
    text: "<p><b>By streamlining the purchase order process through hyperautomation.</b> Open Purchase Orders are automatically analysed and converted into a template per supplier.</p>",
    imageUrl: ImageFour,
    logoUrl: StowLogo,
    iconUrl: IconFour,
    modalContent:
      "<p>A fast-growing company like stow receives a ton of purchase orders that used to require manual entry into an ERP system. Open Purchase Orders are automatically analysed and converted into a template per supplier. This template is sent out to the suppliers with a certain frequency. Supplier's responses are again handled automatically and the ETA's are updated as Purchase Order Confirmations in the SAP ERP system.</p>",
  },
  {
    text: "<p><b>Master your processes.</b> Many businesses understand the challenge of creating lots of master data. It's hard to deliver data objects in a fast, but governed, way while achieving a good quality standard.</p>",
    imageUrl: ImageFive,
    logoUrl: SapienceLogo,
    iconUrl: IconFive,
    modalContent:
      "<p>Many businesses understand the challenge of creating lots of master data. It’s hard to deliver data objects in a fast, but governed, way while achieving a good quality standard. For example: to meet clients’ requirements or local regulations, a high frequency of product creations becomes applicable. What follows, is a process with many objects to be created or adapted. Think of the product itself, the BOM, the master recipe, the product version, the inspection plan; a time-consuming and error-sensitive process. The responsibilities for these adjustments are also fragmented, which leads to delays and bottlenecks in transaction processing caused by the absence of enforcement and monitoring. A low-code app speeds up the process while simplifying it at the same time. In a smart workflow, most tasks are performed by a bot, with a smaller margin for errors. In addition, people are notified when they need to validate or process exceptions, leading to faster transaction processing, improved supply chain planning and a better customer experience.</p>",
  },
];
