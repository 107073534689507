import React from "react";
import HyperLogo from "assets/images/img-contact-hyperchain.png";
import KevinAvatar from "assets/images/img-kenny-avatar.png";
import "./ContactUs.scss";

const ContactUs = () => {
  return (
    <div className="contactUs">
      <div className="contactUs__wrapper">
        <div className="contactUs__wrapper-inner">
          <h2>
            Want to know more?
            <br />
            Contact us!
          </h2>

          <div className="contactUs__wrapper-info">
            <div className="contactUs__wrapper-info__avatar">
              <img src={KevinAvatar} alt="kevin" />
            </div>

            <div className="contactUs__wrapper-info__details">
              <h5>Kenny Mattheyssen</h5>
              <p>Hyperautomation Expert</p>
              <a href="tel:+32479375794">+32 479 37 57 94</a>
              <a href="mailto:kenny.mattheyssen@thevaluechain.eu">
                kenny.mattheyssen@thevaluechain.eu
              </a>
            </div>
          </div>

          <div className="contactUs__wrapper-image">
            <img src={HyperLogo} alt="hyper-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
