import React from 'react'
import './Button.scss'

const Button = ({ color = 'primary', size = 'normal', children, ...props }) => {
  return (
    <button className={`btn btn-${color} btn-${size}`} {...props}>
      {children}
    </button>
  )
}

export default Button
