import React from "react";
import "./TextArea.scss";

const TextArea = ({ register, name, ...props }) => {
  return (
    <div className="textarea-wrapper">
      <textarea {...register(name)} {...props} />
    </div>
  );
};

export default TextArea;
