import { atom } from "recoil";

export const contactModalState = atom({
  key: "contactModalState",
  default: false,
});

export const downloadModalState = atom({
  key: "downloadModalState",
  default: false,
});
