import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { useSetRecoilState } from "recoil";
import { contactModalState, downloadModalState } from "state/atoms/modals";
import { stepNumberState } from "state/atoms/step-number";
import { Button, Download } from "components/Common/Common";
import MobileMenu from "components/MobileMenu/MobileMenu";
import HamburgerIcon from "components/HamburgerIcon/HamburgerIcon";
import { useMobileView } from "utils/useMobileView";
import { useScrollOffset } from "utils/useScrollOffset";
import Logo from "assets/images/logo.png";
import MobileLogo from "assets/images/logo-mobile.png";
import { menuObj } from "./constant";
import "./Header.scss";

const Header = () => {
  const offset = useScrollOffset();
  const [isSticky, setSticky] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const setContactModalState = useSetRecoilState(contactModalState);
  const setDownloadModalState = useSetRecoilState(downloadModalState);
  const setStepNumberState = useSetRecoilState(stepNumberState);

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window === "undefined") return null;

      if (window.pageYOffset > 100) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <header className={`header ${isSticky ? "header-sticky" : ""}`}>
      <div className="header__wrapper">
        <div className="nav">
          <div className="nav__brand">
            {useMobileView() ? (
              <img src={MobileLogo} alt="logo" />
            ) : (
              <img src={Logo} alt="logo" />
            )}
          </div>

          <div className="nav__menu">
            <ul className="nav__menu-list">
              {menuObj?.map((item, id) => (
                <li key={id}>
                  <Link
                    to={item.link}
                    smooth
                    duration={1000}
                    offset={offset}
                    onClick={() => {
                      if (item.stepNumber) {
                        setStepNumberState(item.stepNumber);
                      }
                    }}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
            <Button size="small" onClick={() => setContactModalState(true)}>
              Contact
            </Button>

            <div className="nav__menu-download">
              <Download headerBtn onClick={() => setDownloadModalState(true)} />
            </div>

            <div className="nav__menu-icon">
              <HamburgerIcon
                isOpen={showMobileMenu}
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              />
            </div>
          </div>
        </div>
      </div>

      <MobileMenu
        closeMenu={() => setShowMobileMenu(false)}
        style={{
          opacity: `${showMobileMenu ? "1" : "0"}`,
          visibility: `${showMobileMenu ? "visible" : "hidden"}`,
          transitionDelay: "0.2s",
          transition: "all 0.3s cubic-bezier(1, 0.885, 0.72, 1)",
        }}
      />
    </header>
  );
};

export default Header;
