import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-PXT7QZW',
    dataLayer: {
        userId: '001',
        userProject: 'project'
    }
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
