import React from "react";
import "./HamburgerIcon.scss";

const HamburgerIcon = ({ isOpen, ...props }) => (
  <div className="hamburger" {...props}>
    <div className={`hamburgerIcon ${isOpen ? "hamburgerIconOpen" : ""}`}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
);

export default HamburgerIcon;
