import ImageOne from "assets/icons/1.svg";
import ImageTwo from "assets/icons/2.svg";
import ImageThree from "assets/icons/3.svg";
import ImageFour from "assets/icons/4.svg";
import ImageFive from "assets/icons/5.svg";

export const stepsObj = [
  {
    description:
      "<p><b>Understand hyperautomation</b> Why hyperautomation? What does it involve? Practical examples? What are the benefits? Where is the potential value creation for your business? How do you go about it pragmatically? We answer all these questions during a hyperautomation presentation.</p>",
    imageUrl: ImageOne,
  },
  {
    description:
      "<p><b>Define use cases</b> In break-out sessions, we look for a wide range of concrete use cases for process improvement within each department of your organisation.</p>",
    imageUrl: ImageTwo,
  },
  {
    description:
      "<p><b>Business case</b> We examine the feasibility, impact, and return on investment (ROI) of the proposed use cases. Based on this, we make a smart selection of business cases and draw up a partnership agreement, including costing.</p>",
    imageUrl: ImageThree,
  },
  {
    description:
      "<p><b>Realise</b> We deliver the cases, with a focus on quality. Together, we decide on the best methodology for future cases. The goal: to let your organisation experience the business value of hyperautomation.</p>",
    imageUrl: ImageFour,
  },
  {
    description:
      "<p><b>Expand</b> Together, we evaluate the results of the first cases, the lessons learned and the potential of automation. After the first successes, we continue the organic growth towards a state of hyperautomation.</p>",
    imageUrl: ImageFive,
  },
];
