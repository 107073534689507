import React from "react";
import { useSetRecoilState } from "recoil";
import { caseDataState } from "state/atoms/case-data";
import ViewIcon from "assets/icons/icon-view.svg";
import "./ReferenceCard.scss";

const ReferenceCard = (props) => {
  const { text, imageUrl, iconUrl, logoUrl, content, openModal } = props;
  const setCaseData = useSetRecoilState(caseDataState);

  const openCaseModal = () => {
    setCaseData({ featuredImageUrl: imageUrl, logoUrl, content });
    openModal();
  };

  return (
    <div className="referenceCard">
      <div
        className="referenceCard__wrapper"
        style={{ backgroundImage: `url(${iconUrl})` }}
      >
        <div className="referenceCard__wrapper-text">
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>

        <div className="referenceCard__wrapper-info">
          <h5>Reference case: </h5>

          <div
            className="referenceCard__wrapper-info__icon"
            onClick={openCaseModal}
          >
            <img src={ViewIcon} alt="view-icon" />
            <span>view</span>
          </div>
        </div>

        <div className="referenceCard__wrapper-logo">
          <img src={logoUrl} alt="reference-logo" />
        </div>
      </div>

      <div className="referenceCard__image">
        <img src={imageUrl} alt="reference-img" />
      </div>
    </div>
  );
};

export default ReferenceCard;
