import React from "react";
import "./Video.scss";

const Video = () => {
  return (
    <div className="hyper-video">
        <iframe src="https://www.youtube.com/embed/sKvdy1nl-tA" width="960" height="540" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
  );
};

export default Video;
