import React, { useState } from "react";
import "./Input.scss";

const Input = ({
  type = "text",
  label,
  validation,
  register,
  name,
  required,
}) => {
  const [active, setActive] = useState(false);

  const onFocus = () => {
    setActive(true);
  };

  const onBlur = (event) => {
    console.log(event.target.value.length);
    setActive(event.target.value.length !== 0);
  };

  return (
    <div className="input-wrapper">
      <label className={`${active && "label-active"}`} htmlFor={name}>
        {`${label} ${required ? "*" : ""}`}
      </label>
      <input
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        type={type}
        {...register(name, validation)}
      />
    </div>
  );
};

export default Input;
