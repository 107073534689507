import React from "react";
import Modal from "react-modal";
import { useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { contactModalState } from "state/atoms/modals";
import { Input, TextArea, Button } from "components/Common/Common";
import { sendEmail } from "utils/email";
import CloseSVG from "assets/icons/icon-close.svg";
import "./ContactModal.scss";

const ContactModal = ({ isModalOpen }) => {
  const { register, handleSubmit, reset } = useForm();
  const [, setModalState] = useRecoilState(contactModalState);
  const onSubmit = async (data) => {
    const res = await sendEmail(data, "template_844y6nc");
    reset();
    if (res.status === 200) {
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.55)",
      zIndex: "1100",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      border: "none",
      margin: "0",
      padding: "0",
      width: "100%",
      height: "100%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <Modal
      closeTimeoutMS={1500}
      isOpen={isModalOpen}
      onRequestClose={() => setModalState(false)}
      style={customStyles}
      contentLabel="Contact Modal"
    >
      <div className="contactModal">
        <div className="contactModal__wrapper">
          <div className="contactModal__wrapper-inner">
            <div className="contactModal__info">
              <h3>Contact</h3>
              <div className="contactModal__info-contact">
                <a href="tel:+3238442605">T +32 3844 2605</a>
                <a href="mailto:info@hyperchain.eu">E info@hyperchain.eu</a>
              </div>
            </div>

            <form
              className="contactModal__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="contactModal__form-row">
                <div className="contactModal__form-input">
                  <Input
                    label="Surname"
                    name="user_firstname"
                    register={register}
                    validation={{ required: true }}
                    required
                  />
                </div>
                <div className="contactModal__form-input">
                  <Input
                    label="Name"
                    name="user_lastname"
                    register={register}
                    validation={{ required: true }}
                    required
                  />
                </div>
              </div>
              <div className="contactModal__form-row">
                <div className="contactModal__form-input">
                  <Input
                    type="email"
                    label="E-mail"
                    name="user_email"
                    register={register}
                    validation={{
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    }}
                    required
                  />
                </div>
                <div className="contactModal__form-input">
                  <Input
                    type="tel"
                    label="Phone number"
                    name="user_phonenumber"
                    register={register}
                    validation={{ required: true }}
                    required
                  />
                </div>
              </div>
              <div className="contactModal__form-row">
                <div className="contactModal__form-input">
                  <Input label="Company" name="user_company" register={register} />
                </div>
                <div className="contactModal__form-input contactModal__form-input--hidden"></div>
              </div>
              <div className="contactModal__form-message">
                <TextArea
                  placeholder="Message"
                  rows="4"
                  name="user_message"
                  register={register}
                />
                <div className="contactModal__form-message--button">
                  <Button type="submit">Send</Button>
                </div>
              </div>
            </form>

            <div className="contactModal__close">
              <img
                src={CloseSVG}
                alt="close-icon"
                onClick={() => setModalState(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactModal;
