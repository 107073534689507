import React from "react";
import Modal from "react-modal";
import { useRecoilValue } from "recoil";
import { caseDataState } from "state/atoms/case-data";
import CloseSVG from "assets/icons/icon-close.svg";
import "./CaseModal.scss";

const CaseModal = (props) => {
  const { isModalOpen, closeModal } = props;
  const { content, logoUrl, featuredImageUrl } = useRecoilValue(caseDataState);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.55)",
      zIndex: "1000",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      border: "none",
      margin: "0",
      padding: "0",
      width: "100%",
      height: "100%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <Modal
      closeTimeoutMS={1500}
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Case Modal"
    >
      <div className="caseModal">
        <div className="caseModal__wrapper">
          <div className="caseModal__logo">
            <div className="caseModal__logo-wrapper">
              <img src={logoUrl} alt="case-logo" />
            </div>
          </div>

          <div className="caseModal__featured">
            <img src={featuredImageUrl} alt="case-img" />
          </div>

          <div className="caseModal__content">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>

          <div className="caseModal__close">
            <img src={CloseSVG} alt="close-icon" onClick={closeModal} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CaseModal;
