export const menuObj = [
  {
    title: "Team HyperChain",
    link: "leadingRef",
  },
  {
    title: "Our approach",
    link: "approachRef",
    stepNumber: 5,
  },
  {
    title: "Customer cases",
    link: "casesRef",
  },
];
