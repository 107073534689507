import React from "react";
import { Link } from "react-scroll";
import { Button } from "components/Common/Common";
import { useSetRecoilState } from "recoil";
import { stepNumberState } from "state/atoms/step-number";
import { useScrollOffset } from "utils/useScrollOffset";
import HeroAIImage from "assets/images/img-hero-ai.png";
import "./Hero.scss";
import Download from "../../../components/Common/Download/Download";
import { downloadModalState } from "state/atoms/modals";

const Hero = () => {
  const setDownloadModalState = useSetRecoilState(downloadModalState);

  return (
    <div className="hero">
      <div className="hero__wrapper">
        <div className="hero__wrapper-content">
          <h1>Ready for the next step in process automation?</h1>
          <p>
            <b>Get a head start and embark on your hyperautomation journey.</b>
          </p>
          <Download onClick={() => setDownloadModalState(true)} />
        </div>
      </div>

      <div className="hero__image">
        <img src={HeroAIImage} alt="hero-ai" />
      </div>
    </div>
  );
};

export default Hero;
