import * as emailjs from "emailjs-com";

// YOUR EMAIL.JS API KEY
const API_KEY = "user_NHjVs5XX06aBqRn456NHI";
// YOUR EMAIL.JS TEMPLATE ID

export const sendEmail = async (template_params, TEMPLATE_ID) => {
  //console.log(template_params)
  return emailjs.send("service_sq6mwsh", TEMPLATE_ID, {
    user_email: template_params.user_email,
    user_firstname: template_params.user_firstname,
    user_lastname: template_params.user_lastname,
    user_message: template_params.user_message,
    user_phonenumber: template_params.user_phonenumber,
    user_company: template_params.user_company
  }, API_KEY);
};
