import React from "react";
import Modal from "react-modal";
import { useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { downloadModalState } from "state/atoms/modals";
import { Input, Button } from "components/Common/Common";
import { sendEmail } from "utils/email";
import CloseSVG from "assets/icons/icon-close.svg";
import WhitepaperPDf from "assets/pdf/White_paper_HyperChain-Ready_for_the_next_step _in_process_automation_2023.pdf";
import "./DownloadModal.scss";

const DownloadModal = ({ isModalOpen }) => {
  const { register, handleSubmit, reset } = useForm();
  const [, setModalState] = useRecoilState(downloadModalState);
  const onSubmit = async (data) => {
    const res = await sendEmail(data, "template_kfjg4we");
    reset();
    if (res.status === 200) {
      downloadFile();
    }
  };

  const downloadFile = () => {
    window.open(WhitepaperPDf, "__blank");
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.55)",
      zIndex: "1000",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      border: "none",
      margin: "0",
      padding: "0",
      width: "100%",
      height: "100%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <Modal
      closeTimeoutMS={1500}
      isOpen={isModalOpen}
      onRequestClose={() => setModalState(false)}
      style={customStyles}
      contentLabel="Download Modal"
    >
      <div className="downloadModal">
        <div className="downloadModal__wrapper">
          <div className="downloadModal__info">
            <h3>Download white paper</h3>
          </div>
          <form
            className="downloadModal__form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="contactModal__form-row">
              <div className="contactModal__form-input">
                <Input
                  label="Surname"
                  name="user_firstname"
                  register={register}
                  validation={{ required: true }}
                  required
                />
              </div>
              <div className="contactModal__form-input">
                <Input
                  label="Name"
                  name="user_lastname"
                  register={register}
                  validation={{ required: true }}
                  required
                />
              </div>
            </div>
            <div className="contactModal__form-row">
              <div className="contactModal__form-input">
                <Input
                  type="email"
                  label="E-mail"
                  name="user_email"
                  register={register}
                  validation={{
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  }}
                  required
                />
              </div>
              <div className="contactModal__form-input">
                <Input
                  type="tel"
                  label="Phone number"
                  name="user_phonenumber"
                  register={register}
                  validation={{ required: true }}
                  required
                />
              </div>
            </div>
            <div className="downloadModal__form-button">
              <Button type="submit">Send</Button>
            </div>
          </form>

          <div className="downloadModal__close">
            <img
              src={CloseSVG}
              alt="close-icon"
              onClick={() => setModalState(false)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadModal;
