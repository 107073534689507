import React from "react";
import { useRecoilState } from "recoil";
import { downloadModalState } from "state/atoms/modals";
import { Button, Download } from "components/Common/Common";
import HyperLogo from "assets/images/img-contact-hyperchain.png";
import "./MobileMenu.scss";

const MobileMenu = ({ closeMenu, ...props }) => {
  const [, setDownloadModalState] = useRecoilState(downloadModalState);

  return (
    <div className="mobileMenu" {...props}>
      <div className="mobileMenu__wrapper">
        <div className="mobileMenu__wrapper-info">
          <div className="mobileMenu__wrapper-info__addr">
            <p>
              Satenrozen 10 <br />
              2550 Kontich, Belgium
            </p>
          </div>

          <div className="mobileMenu__wrapper-info__contact">
            <a href="tel:+3238442605">T +32 3844 2605</a>
            <a href="mailto:info@hyperchain.eu">E info@hyperchain.eu</a>
          </div>
        </div>
        <div className="mobileMenu__wrapper-image">
          <img src={HyperLogo} alt="hyper-chain" />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
