import { atom } from "recoil";

export const caseDataState = atom({
  key: "caseDataState",
  default: {
    content: "",
    logoUrl: "",
    featuredImageUrl: "",
  },
});
