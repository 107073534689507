import React from "react";
import DownloadIcon from "assets/icons/icon-download.svg";
import "./Download.scss";

const Download = ({ headerBtn = false, children, ...props }) => {
  return (
    <button
      className={`download-btn ${headerBtn ? "download-btn--header" : ""}`}
      {...props}
    >
      <img src={DownloadIcon} alt="download-icon" />
      {!headerBtn ? (
        <span>Download our white paper</span>
      ) : (
        <span>White paper</span>
      )}
    </button>
  );
};

export default Download;
