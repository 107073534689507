import React, { useEffect } from "react";
import Modal from "react-modal";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import HomePage from "pages/Home";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
import "./styles/index.scss";

const App = () => {
  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);

  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={HomePage} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
};

export default App;
