import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import { useRecoilValue } from "recoil";
import { stepNumberState } from "state/atoms/step-number";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import StepCard from "components/StepCard/StepCard";
import LaunchEvent from "sections/Home/LaunchEvent/LaunchEvent";
import { stepsObj } from "./constant";
import "./Approach.scss";
import VideoModal from '../../../components/videoModal/VideoModal';

const Approach = () => {
  SwiperCore.use([Pagination]);
  const [swiper, setSwiper] = useState(null);
  const stepNumber = useRecoilValue(stepNumberState);

  useEffect(() => {
    const slideTo = (index) => {
      if (swiper) {
        swiper.slideTo(index);
      }
    };

    slideTo(stepNumber);
  }, [stepNumber, swiper]);

  return (
    <div className="gradient-sections">
      <Element name="approachRef">
        <div className="approach">
          <div className="approach__wrapper">
            <h3>
              <b>
                Hyperautomation requires a specific mindset and skills. The
                HyperChain team of TheValueChain developed a five-step approach.
              </b>
            </h3>
          </div>

          <div className="approach__slider">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ type: "bullets", clickable: true }}
              onSwiper={setSwiper}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 2.25,
                },
                1024: {
                  slidesPerView: 2.25,
                  spaceBetween: 80,
                },
                1280: {
                  slidesPerView: 2.5,
                  spaceBetween: 120,
                },
              }}
            >
              {stepsObj?.map((step, id) => (
                <SwiperSlide key={id}>
                  <StepCard
                    description={step.description}
                    imageUrl={step.imageUrl}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Element>

                <VideoModal></VideoModal>
      {/* <Element name="eventRef">
        <LaunchEvent />
      </Element> */}
    </div>
  );
};

export default Approach;
