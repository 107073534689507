import React, { useEffect, useState, useRef } from "react";
import "./VideoModal.scss";
import playButton from "../../assets/images/play-button-svgrepo-com.svg";
import headerImage from "../../assets/images/event_image.png";
import closeIcon from "../../assets/icons/icon-close-white.svg";
import video from "../../assets/images/video-new.mp4";

const VideoModal = () => {
  const [videoActive, setVideoActive] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    if (videoActive) console.log(videoRef);
    if (videoActive) videoRef.current.play();
  }, [videoActive]);

  return (
    <>
      <div className="video-preview">
        <img className="video-preview__header" src={headerImage} />
        <h2>Our HyperChain launch event was a <span>big</span> success!</h2>
        <button
          onClick={() => setVideoActive(!videoActive)}
          className="video-preview__button"
          >
          <img className="video-preview__button-icon" src={playButton} />
        </button>
          <p>Watch our aftermovie here</p>
      </div>
      {videoActive ? (
        <div className="video-modal">
          <img onClick={() => setVideoActive(!videoActive)} className="video-modal__close" src={closeIcon} />
          <video 
          controls
            ref={videoRef}
            className="video-modal__video"
            width="100%"
            height="100%"
            autoplay
          >
            <source src={video} type="video/mp4" />
          </video>
          <div
            onClick={() => setVideoActive(!videoActive)}
            className="video-modal__background"
          ></div>
        </div>
      ) : (
        ``
      )}
    </>
  );
};

export default VideoModal;
