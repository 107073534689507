import React, { useState } from "react";
import { Element } from "react-scroll";
import { useSetRecoilState } from "recoil";
import { downloadModalState } from "state/atoms/modals";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Download } from "components/Common/Common";
import CaseModal from "components/CaseModal/CaseModal";
import ReferenceCard from "components/ReferenceCard/ReferenceCard";
import { valuesObj } from "./constant";
import "./HowValue.scss";

const HowValue = () => {
  SwiperCore.use([Pagination]);
  const setDownloadModalState = useSetRecoilState(downloadModalState);
  const [isOpenCaseModal, setOpenCaseModal] = useState(false);

  return (
    <div className="howValue">
      <div className="howValue__shape">
        <div className="howValue__shape-inner">
          <p>
            <b>
              HyperChain believes that the SAP Business Technology Platform
              (BTP) in the cloud offers the right capabilities.
            </b>{" "}
            We see it as a big toolbox. And depending on the automation cases
            that play, the suitable tools (= BTP services) are selected and
            activated. Think about low-code, and even no-code, developed apps
            that interact with smart workflows with embedded AI skills that
            alternates user and bot (RPA) tasks and communicates again with any
            back-end system. It gives endless possibilities. This doesn’t mean
            that all ‘tools’ need to be SAP BTP services. It’s one of the
            strengths of this automation platform that it can perfectly
            integrate third-party tools (e.g.: UiPath or BluePrism RPA that
            might already be in place).
          </p>

          <div className="howValue__shape-inner__cta">
            <Download onClick={() => setDownloadModalState(true)} />
          </div>
        </div>
      </div>

      <Element name="casesRef">
        <div className="howValue__slider">
          <div className="howValue__slider-title">
            <h5>Now, let’s make it tangible!</h5>
            <h2>How do we add value?</h2>
          </div>

          <div className="howValue__slider-wrapper">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ type: "bullets", clickable: true }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 2.5,
                },
                1024: {
                  slidesPerView: 1.5,
                  spaceBetween: 30,
                },
              }}
            >
              {valuesObj?.map((item, id) => (
                <SwiperSlide key={id}>
                  <ReferenceCard
                    text={item.text}
                    imageUrl={item.imageUrl}
                    iconUrl={item.iconUrl}
                    logoUrl={item.logoUrl}
                    content={item.modalContent}
                    openModal={() => setOpenCaseModal(true)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Element>

      {isOpenCaseModal && (
        <CaseModal
          isModalOpen={isOpenCaseModal}
          closeModal={() => setOpenCaseModal(false)}
        />
      )}
    </div>
  );
};

export default HowValue;
